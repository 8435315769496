import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import styles from "../styles/blog-post.module.scss"

const BlogTemplate = ({ data }) => {
  const { localImage, frontmatter, html } = data.markdownRemark
  const { title, date, description } = frontmatter
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image={localImage.publicURL}
      />
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <h2>{title}</h2>
          <div className={styles.imageWrapper}>
            <Img
              fluid={localImage.childImageSharp.fluid}
              alt={title}
              style={{ height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
            />
          </div>
          <p className={styles.date}>
            {new Date(date).toLocaleString("pt-PT", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const BlogPostTemplateQuery = graphql`
  query BlogPageQuery($slug: String!) {
    site {
      siteMetadata {
        title
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date
        title
        description
      }
      localImage {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      html
    }
  }
`
